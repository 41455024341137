import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";
import { AGENT_PORTAL_URL, APP_URL } from "../../../config/config";
import { addSettings } from "../../../actions";
import {
  getAdminSupportPage,
  getKnowledgeBase,
} from "./../../../config/api_calls";
import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";

import Tooltip from "@material-ui/core/Tooltip";
const auth = new HelperClass();

class KnowledgeBase extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      waitMessage: "",
      tableLoader: true,
      nrc_rate_type: "Flat",
      defaultNrc: "",
      defaultMrc: "",
      new_nrc: "",
      new_mrc: "",
      rate_type: "",
      rate: "",
      update_nrc: "",
      update_mrc: "",
      update_rate: "",
      product_id: "",
      pageContent: "",
      pageTitle: "",
      mrc_rate_type: "Flat",
      service_title: "",
      service_type_edit: "",
    };
  }
  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  async componentDidMount() {
    console.log("this.props: ", this.props);
    let pageUrl = "knowledgeBase";
    const servicesResponce = await getAdminSupportPage(
      auth.getAccount(),
      auth.getToken(),
      pageUrl
    );
    console.log("servicesResponce: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        pageContent: servicesResponce.data.data[0]["page_content"],
        pageTitle: servicesResponce.data.data[0]["page_title"],
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
    await this.get_knowledge_base();
  }

  get_knowledge_base = async () => {
    const servicesResponce = await getKnowledgeBase(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("servicesResponce: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  render() {
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Knowledge Base" icon="icon ni ni-help" />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          {this.state.tableLoader === true ? (
                            tableLoader()
                          ) : (
                            <div class="nk-block">
                              <div class="nk-block-head">
                                <div class="nk-block-head-content">
                                  <h5 class="nk-block-title title">
                                    Knowledge Base
                                  </h5>
                                  <div class="nk-block-des">
                                    <p>
                                      Explore step-by-step guides to efficiently
                                      manage your workflow, from client profiles
                                      to contracts, orders, and document
                                      organization—all in one place.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {this.state.tableData &&
                              this.state.tableData.length > 0 ? (
                                <div class="card card-bordered">
                                  <div class="card-inner-group">
                                    {this.state.tableData.map(
                                      (pages, index) => (
                                        <div
                                          class="card-inner"
                                          style={{ padding: "1rem" }}
                                          key={`pag${index}`}
                                        >
                                          <div class="between-center flex-wrap flex-md-nowrap g-3">
                                            <div class="nk-block-text">
                                              <h6>
                                                <a
                                                  href={`${APP_URL}support/${pages.page_link}`}
                                                  target="_blank"
                                                >
                                                  {pages.page_title}
                                                </a>
                                              </h6>
                                              <p>{pages.page_content_short}</p>
                                            </div>
                                            <div class="nk-block-actions">
                                              <Tooltip
                                                title="Open link"
                                                placement="right"
                                              >
                                                <a
                                                  href={`${APP_URL}support/${pages.page_link}`}
                                                  target="_blank"
                                                  className="btn btn-primary"
                                                >
                                                  <em class="icon ni ni-arrow-up-right"></em>
                                                  <span>View</span>
                                                </a>
                                              </Tooltip>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeBase);
