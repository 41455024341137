import React from "react";
import ContentLoader from "react-content-loader";

export const InvoiceLoader = (props) => {
  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
    {/* Tabs */}
    <ContentLoader
      speed={2}
      width="100%"
      height={50}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="0" rx="4" ry="4" width="150" height="20" />
      <rect x="160" y="0" rx="4" ry="4" width="120" height="20" />
      <rect x="290" y="0" rx="4" ry="4" width="160" height="20" />
      <rect x="460" y="0" rx="4" ry="4" width="180" height="20" />
    </ContentLoader>

    {/* Invoice Header */}
    <ContentLoader
      speed={2}
      width="100%"
      height={120}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="30" rx="4" ry="4" width="200" height="20" />
      <rect x="0" y="60" rx="4" ry="4" width="300" height="15" />
      <rect x="0" y="90" rx="4" ry="4" width="150" height="15" />
    </ContentLoader>

    {/* Invoice Table */}
    <ContentLoader
      speed={2}
      width="100%"
      height={300}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      {/* Table Header */}
      <rect x="0" y="10" rx="4" ry="4" width="15%" height="20" />
      <rect x="17%" y="10" rx="4" ry="4" width="15%" height="20" />
      <rect x="35%" y="10" rx="4" ry="4" width="30%" height="20" />
      <rect x="70%" y="10" rx="4" ry="4" width="10%" height="20" />
      <rect x="82%" y="10" rx="4" ry="4" width="15%" height="20" />

      {/* Rows */}
      {Array.from({ length: 4 }).map((_, i) => (
        <>
          <rect x="0" y={50 + i * 40} rx="4" ry="4" width="15%" height="20" />
          <rect x="17%" y={50 + i * 40} rx="4" ry="4" width="15%" height="20" />
          <rect x="35%" y={50 + i * 40} rx="4" ry="4" width="30%" height="20" />
          <rect x="70%" y={50 + i * 40} rx="4" ry="4" width="10%" height="20" />
          <rect x="82%" y={50 + i * 40} rx="4" ry="4" width="15%" height="20" />
        </>
      ))}
    </ContentLoader>

    {/* Summary Section */}
    <ContentLoader
      speed={2}
      width="100%"
      height={150}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="0" rx="4" ry="4" width="50%" height="20" />
      <rect x="0" y="40" rx="4" ry="4" width="30%" height="20" />
      <rect x="0" y="80" rx="4" ry="4" width="70%" height="20" />
    </ContentLoader>
  </div>
  );
};
