import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import {
  getRfqQuestions,
  deleteAnswerOption,
  deleteQuestions,
  listQuestionGroup,
} from "./../../../config/reseller_api_calls";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { addSettings, addMsaInfo } from "../../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { Fileupload } from "../../../components/Image/Fileupload";
import Tooltip from "@material-ui/core/Tooltip";
import MutextField from "@material-ui/core/TextField";
import dateFormat, { masks } from "dateformat";
import Select from "react-select";
import DataTable from "../../../components/Tables/DataTable";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const auth = new HelperClass();

class QuestionsLibrary extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      filteredTableData: [],
      errorMessage: "",
      agentId: "",
      successMessage: "",
      SmtpErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_reg_button: false,
      smtp_test_button: false,
      waitMessage: "",
      tableLoader: true,
      signup_link: "",
      copied: false,
      copied2: false,
      cpied_value: "",
      current_id: "",
      reg_button: false,
      reg_button_login: false,
      cancel_button: false,
      tableLoader: true,
      login_url: "",
      logo: "",
      signature_image: "",
      smtp_host: "",
      smtp_user: "",
      smtp_password: "",
      smtp_method: "tls",
      smtp_port: "",
      errorHostName: "",
      errorUser: "",
      errorPassword: "",
      from_email: "",
      errorFromEmail: "",
      is_working: "",
      selected_options: [],
      optionErrorMessage: "",
      optionSuccessMessage: "",
      all_question_groups: [],
      selected_groupId: null,
      columnServices: [
        { name: "ID" },
        { name: "QUESTION" },
        {
          name: "DATE",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "TYPE",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.viewQuestionType(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "STATUS",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "ACTIONS",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.showAnswerOptions(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Show answer options"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-list"></em>
                                  <span>Answer Options</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={() =>
                                    this.addMenuHistory(
                                      "/EditQuestionsLibrary/" +
                                        tableMeta.rowData[0]
                                    )
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Assigned Services"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Edit</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.deleteQuestions(value, tableMeta)
                                  }
                                  title="Delete Question"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-trash"></em>
                                  <span>Delete</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  async componentDidMount() {
    this.getQuestionGroups();
    const servicesResponce = await getRfqQuestions(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("getRfqQuestions: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        filteredTableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  }

  defaultText = (value, tableMeta) => {
    let is_default = value;

    if (is_default === 0) {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-signin"></em> Inactive
        </span>
      );
    } else if (is_default === 1) {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    }
  };

  showAnswerOptions = (value, tableMeta) => {
    let question_options = tableMeta.rowData[5];
    //'textfield','textarea','radio','checkbox','dropdown'
    //console.log("domain_str: ", domain_str);
    if (question_options && question_options.length > 0) {
      this.setState({
        selected_options: question_options,
        optionErrorMessage: "",
      });
    } else {
      this.setState({
        optionErrorMessage: "Response choices are currently unavailable.",
      });
    }
    window.$("#modalFormOptions").modal("show");
  };

  viewQuestionType = (value, tableMeta) => {
    let domain_str = value;
    //'textfield','textarea','radio','checkbox','dropdown'
    //console.log("domain_str: ", domain_str);
    if (domain_str === "textfield") {
      return <span className="badge badge-dot badge-dark">Text Field</span>;
    } else if (domain_str === "textarea") {
      return (
        <div>
          <span className="badge badge-dot badge-dark"> Text Area</span>
        </div>
      );
    } else if (domain_str === "radio") {
      return (
        <div>
          <span className="badge badge-dot badge-dark"> Radio Button</span>
        </div>
      );
    } else if (domain_str === "checkbox") {
      return (
        <div>
          <span className="badge badge-dot badge-dark"> Checkbox</span>
        </div>
      );
    } else if (domain_str === "dropdown") {
      return (
        <div>
          <span className="badge badge-dot badge-dark"> Drop Down</span>
        </div>
      );
    }
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM TT");
  };

  deleteQuestions = async (value, tableMeta) => {
    let question_id = tableMeta.rowData[0];
    this.setState({
      errorMessage: "",
      successMessage: "",
      disabled: true,
    });

    const servicesResponce = await deleteQuestions(
      auth.getAccount(),
      auth.getToken(),
      question_id
    );
    console.log("addRfqQuestion: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        errorMessage: "The question not found.",
        successMessage: "",
        disabled: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Question deleted successfully.",
        disabled: false,
      });
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } else {
      this.setState({
        errorMessage: "There is some error while deleting the question.",
        successMessage: "",
        disabled: false,
      });
    }

    setTimeout(() => {
      this.setState({ errorMessage: "", successMessage: "" });
    }, 5000);
  };

  deleteOption = async (id, question_id) => {
    this.setState({
      optionErrorMessage: "",
      optionSuccessMessage: "",
      disabled: true,
    });

    const servicesResponce = await deleteAnswerOption(
      auth.getAccount(),
      auth.getToken(),
      id,
      question_id
    );
    console.log("addRfqQuestion: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        optionErrorMessage:
          "There is some error while deleting the answer option.",
        optionSuccessMessage: "",
        disabled: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        optionErrorMessage: "",
        optionSuccessMessage: "Answer option deleted successfully.",
        disabled: false,
      });
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } else {
      this.setState({
        optionErrorMessage:
          "There is some error while adding the answer option.",
        optionSuccessMessage: "",
        disabled: false,
      });
    }

    setTimeout(() => {
      this.setState({ optionErrorMessage: "", optionSuccessMessage: "" });
    }, 5000);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  validateIPAddress = (ipAddress) => {
    const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
    const isValid = ipRegex.test(ipAddress);
    //console.log(`Is IP Address valid? ${isValid}`);
    return isValid;
  };

  validateDomain = (domain) => {
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = domainRegex.test(domain);
    //console.log(`Is domain valid? ${isValid}`);
    if (isValid) {
      return true;
    }
    return false;
  };

  validateEmailAdd = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    //console.log("isValidEmail: " + " " + email + " = " + isValidEmail);
    if (isValidEmail) {
      return true;
    }
    return false;
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  handleChangeSearchBar = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);
    if (changed_value) {
      const filteredData = this.state.tableData.filter(
        (question) => question[6] === changed_value.value
      );
      this.setState({
        tableLoader: true,
        selected_groupId: changed_value,
        filteredTableData: filteredData,
      });
    } else {
      this.setState({
        tableLoader: true,
        selected_groupId: null,
        filteredTableData: this.state.tableData,
      });
    }
    setTimeout(() => {
      this.setState({
        tableLoader: false,
      });
    }, 500);
  };
  getQuestionGroups = async () => {
    const getQuestionGroupsResponce = await listQuestionGroup(
      auth.getAccount(),
      auth.getToken()
    );
    console.log(
      "getQuestionGroupsResponce.data.data: ",
      getQuestionGroupsResponce.data
    );

    if (
      getQuestionGroupsResponce.data.status === 403 ||
      getQuestionGroupsResponce.data.errors === "authentication missing" ||
      getQuestionGroupsResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (getQuestionGroupsResponce.data.status === 404) {
      this.setState({
        all_question_groups: [],
      });
    } else if (
      getQuestionGroupsResponce.data.status === 200 &&
      getQuestionGroupsResponce.data.message === "success"
    ) {
      let all_groups = [];
      if (
        getQuestionGroupsResponce.data.data &&
        getQuestionGroupsResponce.data.data.length > 0
      ) {
        for (let i = 0; i < getQuestionGroupsResponce.data.data.length; i++) {
          let newArr = {
            label: getQuestionGroupsResponce.data.data[i][1],
            value: getQuestionGroupsResponce.data.data[i][4],
          };
          all_groups.push(newArr);
        }
      }
      this.setState({
        all_question_groups: all_groups,
      });
    } else {
      this.setState({
        all_question_groups: [],
      });
    }
  };
  
  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name="RFQ Questions Library"
                        icon="icon ni ni-file-docs"
                      />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContentAgent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <a
                              style={{ cursor: "pointer", color: "#fff" }}
                              onClick={() =>
                                this.addMenuHistory("/AddQuestionsLibrary")
                              }
                              className="btn btn-primary"
                            >
                              <em className="icon ni ni-plus-round"></em>
                              &nbsp;Add New Question
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-gs">
                {/* Table 5th Col Start */}
                <div className="col-xxl-12">
                  <div className="nk-block nk-block-lg">
                    <div class="card" style={{ borderRadius: "0px" }}>
                      <div class="card-inner">
                        <div className="row g-gs mb-3">
                          <div className="col-md-6">
                            <div class="form-group">
                              <div class="form-label-group">
                                <label class="form-label">
                                  Select Questions Group
                                  <span class="text-danger"></span>
                                </label>
                              </div>
                              <div class="form-control-group">
                                <Select
                                  options={this.state.all_question_groups}
                                  name="selected_groupId"
                                  placeholder="Select a group of questions."
                                  value={this.state.selected_groupId}
                                  autoComplete="off"
                                  emptyMessage="Group not found"
                                  onChange={this.handleChangeSearchBar}
                                  isClearable
                                  isSearchable
                                  components={animatedComponents}
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      lineHeight: "40px",
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* START DATATABLE */}
                    {/* {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          tableData={this.state.filteredTableData}
                          title=""
                        />
                      )} */}

                    {/* END TABLE */}
                    {/* </div> */}
                    {/* <div className="nk-block nk-block-lg"> */}
                    {this.state.errorMessage !== "" ? (
                      <div
                        className="example-alert"
                        style={{ marginBottom: "15px" }}
                      >
                        <div className="alert alert-pro alert-danger">
                          <div className="alert-text">
                            <h4>Error</h4>
                            <p>{this.state.errorMessage}</p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.successMessage !== "" ? (
                      <div
                        className="example-alert"
                        style={{ marginBottom: "15px" }}
                      >
                        <div className="alert alert-pro alert-success">
                          <div className="alert-text">
                            <h4>Success</h4>
                            <p>{this.state.successMessage}</p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/* START DATATABLE */}
                    {this.state.tableLoader === true ? (
                      tableLoader()
                    ) : (
                      <DataTable
                        columns={this.state.columnServices}
                        tableData={this.state.filteredTableData}
                        title=""
                      />
                    )}

                    {/* END TABLE */}
                  </div>
                </div>
                {/* Table 5th Col End */}
              </div>
              <div class="modal fade" tabindex="-1" id="modalFormOptions">
                <div
                  class="modal-dialog modal-lg modal-dialog-top"
                  role="document"
                >
                  <div class="modal-content">
                    <a
                      href="#"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross"></em>
                    </a>
                    <div class="modal-header">
                      <h5 class="modal-title">Answer Options</h5>
                    </div>
                    <div class="modal-body">
                      {this.state.optionErrorMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.optionErrorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.optionSuccessMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.optionSuccessMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {this.state.selected_options &&
                      this.state.selected_options.length > 0 ? (
                        <>
                          <div className="col-md-12">
                            <div className="sp-plan-desc sp-plan-desc-mb">
                              <table className="table">
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col">Answer Option</th>
                                    <th scope="col" className="text-right">
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.selected_options.map(
                                    (miscrate, idx) => (
                                      <tr key={`mis${idx}`}>
                                        <td>{miscrate.question_options}</td>
                                        <td className="text-right">
                                          <a
                                            onClick={() =>
                                              this.deleteOption(
                                                miscrate.id,
                                                miscrate.question_id
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                            title="Delete Option"
                                            disabled={this.state.disabled}
                                          >
                                            <span class="badge badge-outline-dark">
                                              <em className="icon ni ni-trash"></em>
                                            </span>
                                          </a>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="example-alert mt-4">
                            <div class="alert alert-light">
                              Answer options, often referred to as choices or
                              alternatives, are the available selections
                              provided for a given question in a quiz, survey,
                              or assessment. Participants are typically required
                              to choose one or more of these options to respond
                              to the question.{" "}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(QuestionsLibrary);
